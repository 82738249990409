export enum Status {
	OPEN = 'OPEN',
	PENDING = 'PENDING',
	BOOKED = 'BOOKED',
	NOT_BOOKED = 'NOT_BOOKED',
	UNKNOWN = 'UNKNOWN',
}

export enum RawStatus {
	OPEN = 'OPEN',
	PENDING = 'PENDING',
	BOOKED = 'BOOKED',
	NOT_BOOKED = 'NOT_BOOKED',
	UNKNOWN = 'UNKNOWN',
}
