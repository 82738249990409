import { useContext } from 'react';

import { FeatureToggle } from '@vakantiesnl/services/src/hooks/rest/feature-toggle';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { ApplicationStoreContext } from './applicationContext';
import { ApplicationState } from './applicationStore';

export function useApplicationStore<T>(
	selector: (state: ApplicationState) => T,
	equalityFn?: (left: T, right: T) => boolean,
): T {
	const store = useContext(ApplicationStoreContext);

	if (!store) throw new Error('Missing ApplicationStoreContext.Provider in the tree');

	return useStoreWithEqualityFn(store, selector, equalityFn);
}

export function useFeatures(): FeatureToggle {
	try {
		return useApplicationStore((s) => s.features);
	} catch (error) {
		/** Returning an empty object in case Missing ApplicationStoreContext.Provider in the tree.
		 * In case other apps without zustand context are using a shared component where useFeatures is called.
		 */
		return {};
	}
}
