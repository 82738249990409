export const DEFAULT_DURATIONS = '6-10';
export const DEFAULT_DURATIONS_ARRAY = [6, 7, 8, 9, 10];
export const DEFAULT_SHORT_DURATIONS = '2-5';
export const DEFAULT_SHORT_DURATIONS_ARRAY = [2, 3, 4, 5];

/** Give up a range and create a number array from this */
export const range = (start: number, end: number): number[] =>
	new Array(end - start + 1).fill(undefined).map((_, i) => i + start);

/** Give up a number array or string array with numbers and sort it */
export const sortNumberArray = (array: number[] | string[], descending?: boolean): number[] => {
	// convert string array to numbers
	const numberArray = [...array].map(Number);
	return descending ? numberArray.sort((a, b) => b - a) : numberArray.sort((a, b) => a - b);
};

/** Convert durations to durations_flight (number[]) for the search api request */
export const formatDurationsSearchQuery = (durations?: number[] | string | null): number[] | null => {
	if (!durations?.length) return null;

	const range = typeof durations === 'string' ? parseRangeToArray(durations) : durations;

	if (!range) {
		return null;
	}

	// Expects the amount of days MINUS 1, so 8 days become 7
	return sortNumberArray(range.map((duration) => Number(duration) - 1));
};

// Valid value is either an array of numbers or a string with a range with format: {from}-{to}
export function parseQueryToValidDurations(durations: string | string[] | undefined): string | number[] | null {
	if (durations === undefined) {
		return null;
	}

	// If durations is already an array, map each element to a number
	if (Array.isArray(durations)) {
		const parsedDurations = durations.map(Number);

		if (parsedDurations.some((d) => !isValidDurationNumber(d))) {
			return null;
		}

		return parsedDurations;
	}

	// Decode in case we receive the value from the url
	const decodedDurations = decodeURIComponent(durations);

	// If value contains a "-", interpret it as a range "from-to"
	if (decodedDurations.includes('-')) {
		const [from, to] = durations.split('-').map(Number);

		if (!isValidDurationNumber(from) || !isValidDurationNumber(to)) {
			return null;
		}

		return [from, to].join('-');
	}

	// If value contains a "+", interpret it as a joined array
	if (decodedDurations.includes('+')) {
		const splitDurations = decodedDurations.split('+').map(Number);

		if (splitDurations.some((d) => !isValidDurationNumber(d))) {
			return null;
		}

		return splitDurations;
	}

	// Else interpret string value as a single number
	const parsedDuration = Number(decodedDurations);

	if (isValidDurationNumber(parsedDuration)) {
		return [parsedDuration];
	}

	// If value is invalid, return null
	return null;
}

export function convertDurationsToArray(durations: string | number[] | null): number[] {
	if (durations === null) {
		return [];
	}

	if (Array.isArray(durations)) {
		return durations;
	}

	return parseRangeToArray(durations) || [];
}

export function parseToDurationNumber(value: string): number | null {
	const valueAsNumber = Number(value);

	return isValidDurationNumber(valueAsNumber) ? valueAsNumber : null;
}

function isValidDurationNumber(value: number): boolean {
	return isFinite(value) && value > 0;
}

function parseRangeToArray(durations: string): number[] | null {
	const [from, to] = durations.split('-').map(Number);

	if (!isValidDurationNumber(from) || !isValidDurationNumber(to)) {
		return null;
	}

	return Array.from({ length: to + 1 - from }, (_, i) => i + from);
}
