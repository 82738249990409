export type RawPayments = {
	is_paid: boolean;
	is_refunded: boolean;
	items: {
		status: string;
		method: PaymentMethodEnum;
	}[];
};

export type Payments = {
	isPaid: boolean;
	selectedPaymentMethod?: PaymentMethodEnum;
};

export enum PaymentMethodEnum {
	IDEAL = 'IDEAL',
	CREDITCARD = 'CARD',
	BANCONTACT = 'BANCONTACT',
}

export type RawPaymentCode = {
	result_code: RawPaymentCodeEnum;
};

export type PaymentCode = {
	resultCode: PaymentCodeEnum;
};

export enum RawPaymentCodeEnum {
	AUTHORISED = 'AUTHORISED',
	AUTHENTICATIONFINISHED = 'AUTHENTICATIONFINISHED',
	AUTHENTICATIONNOTREQUIRED = 'AUTHENTICATIONNOTREQUIRED',
	CANCELLED = 'CANCELLED',
	CHALLENGESHOPPER = 'CHALLENGESHOPPER',
	ERROR = 'ERROR',
	IDENTIFYSHOPPER = 'IDENTIFYSHOPPER',
	PENDING = 'PENDING',
	PRESENTTOSHOPPER = 'PRESENTTOSHOPPER',
	RECEIVED = 'RECEIVED',
	REDIRECTSHOPPER = 'REDIRECTSHOPPER',
	REFUSED = 'REFUSED',
	UNKNOWN = 'UNKNOWN',
}

export enum PaymentCodeEnum {
	AUTHORISED = 'AUTHORISED',
	AUTHENTICATIONFINISHED = 'AUTHENTICATIONFINISHED',
	AUTHENTICATIONNOTREQUIRED = 'AUTHENTICATIONNOTREQUIRED',
	CANCELLED = 'CANCELLED',
	CHALLENGESHOPPER = 'CHALLENGESHOPPER',
	ERROR = 'ERROR',
	IDENTIFYSHOPPER = 'IDENTIFYSHOPPER',
	PENDING = 'PENDING',
	PRESENTTOSHOPPER = 'PRESENTTOSHOPPER',
	RECEIVED = 'RECEIVED',
	REDIRECTSHOPPER = 'REDIRECTSHOPPER',
	REFUSED = 'REFUSED',
	UNKNOWN = 'UNKNOWN',
}

export enum ActionCodes {
	VOUCHER = 'VOUCHER',
	REDIRECT = 'REDIRECT',
	QRCODE = 'QRCODE',
	AWAIT = 'AWAIT',
	SDK = 'SDK',
	THREEDS2FINGERPRINT = 'THREEDS2FINGERPRINT',
	THREEDS2CHALLENGE = 'THREEDS2CHALLENGE',
}

export type PaymentAction = {
	url: string;
	type: ActionCodes;
	method?: string;
	data?: {
		key: string;
		value: string;
	};
};

export type PaymentDetailRequestBody = {
	details: PaymentDetailsParams;
};

export type RawPaymentDetailsParams = {
	redirect_result?: string;
	three_ds_result?: string;
};

export type PaymentDetailsParams = {
	redirectResult?: string;
	threeDSResult?: string;
};
