export enum TransferProductType {
	SHUTTLE = 1,
	PRIVATE = 0,
}

export type RawSelectableTransfer = {
	transfer_supplier_code: string;
	product_id_outbound: string;
	product_id_inbound: string;
	product_type_code: number;
	product_type_name: string;
	description: string;
	transfer_duration: number;
	price_type: 'total' | 'person';
	currency: string;
	price: number;
};

export type SelectableTransfer = {
	transferSupplierCode: string;
	productIdOutbound: string;
	productIdInbound: string;
	productTypeCode: number;
	productTypeName: string;
	description: string;
	transferDuration: number;
	priceType: 'total' | 'person';
	price: number;
};
