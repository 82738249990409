export enum InsuranceType {
	TRAVEL = 'TRAVEL',
	CANCELLATION = 'CANCELLATION',
}

type PatternPriceType = {
	currency: string;
	amount: number;
};

export interface PatternInsuranceItem {
	proposalType: string;
	mustAccept: null | boolean;
	proposalId: string;
	price: {
		consumerPrice: PatternPriceType;
		servicePrice: PatternPriceType;
		insurancePrice: PatternPriceType;
		netPrice: PatternPriceType;
		taxes: {
			taxName: string;
			taxRate: number;
			taxAmount: PatternPriceType;
		}[];
	};
	productName: string;
	productType: string;
	documentation: {
		wordingUrl: string;
		ipidUrl: string;
		scheduleUrl: string;
		termsAndConditionsUrl: string;
	};
	readMoreUrl: null | string;
	proposalCommunications: {
		html: null | string;
		communications: {
			logo: string;
			description: string;
			title: string;
			readmode: string;
		};
		additionalInfo: Record<string, string>;
	};
}

export interface RawPatternInsuranceItem {
	proposal_type: string;
	must_accept: null | boolean;
	proposal_id: string;
	price: {
		consumer_price: PatternPriceType;
		service_price: PatternPriceType;
		insurance_price: PatternPriceType;
		net_price: PatternPriceType;
		taxes: {
			tax_name: string;
			tax_rate: number;
			tax_amount: PatternPriceType;
		}[];
	};
	product_name: string;
	product_type: string;
	documentation: {
		wording_url: string;
		ipid_url: string;
		schedule_url: string;
		terms_and_conditions_url: string;
	};
	read_more_url: null | string;
	proposal_communications: {
		html: null | string;
		communications: {
			logo: string;
			description: string;
			title: string;
			readmode: string;
		};
		additional_info: Record<string, string>;
		components: [];
		plans: [];
	};
	booking_refs: [];
	plans: [];
	components: [];
}

export interface Insurance {
	type: InsuranceType;
	serviceCode: string;
	requirementCode: string; // Returns a questionmark for pattern insurances
	serviceTypeCode: string; // Returns a questionmark for pattern insurances
	name: string;
	price: number;
	taxes: number;
	priceTotal: number;
	patternProposalId?: string;
	patternProductId?: null;
	proposalDetails: PatternInsuranceItem;
}

export interface RawInsurance {
	type: InsuranceType;
	service_code: string;
	requirement_code: string; // Returns a questionmark for pattern insurances
	service_type_code: string; // Returns a questionmark for pattern insurances
	name: string;
	price: number;
	taxes: number;
	price_total: number;
	pattern_proposal_id?: string;
	pattern_product_id?: null;
	proposal_details: RawPatternInsuranceItem;
}

export type PatchInsuranceRequestBody = {
	insurances?:
		| {
				product_type: string;
		  }[]
		| null;
};
