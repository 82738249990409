import getConfig from 'next/config';
import StackdriverErrorReporter from 'stackdriver-errors-js';

const { publicRuntimeConfig } = getConfig() ?? {};

let errorHandler:
	| StackdriverErrorReporter
	| {
			report: (error: string | Error) => void;
	  };

/**
 * Add clientside error handler on production environments
 * Else add a noop function
 */
if (
	typeof window !== 'undefined' &&
	publicRuntimeConfig?.DISABLE_LOGGING !== 'TRUE' &&
	process.env.NODE_ENV === 'production' &&
	process.env.NEXT_PUBLIC_ERROR_REPORTING_API_KEY &&
	process.env.NEXT_PUBLIC_TARGET_PROJECT
) {
	errorHandler = new StackdriverErrorReporter();
	if (errorHandler instanceof StackdriverErrorReporter)
		errorHandler.start({
			key: process.env.NEXT_PUBLIC_ERROR_REPORTING_API_KEY,
			projectId: process.env.NEXT_PUBLIC_TARGET_PROJECT,
			service: process.env.NEXT_PUBLIC_GAE_SERVICE,
			version: process.env.NEXT_PUBLIC_TAG_NAME,
		});
} else {
	errorHandler = {
		// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
		report: (_error: string | Error): void => {},
	};
}

export { errorHandler };
