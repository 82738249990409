import { ResultCode } from '@adyen/adyen-web/dist/types/components/types';

export enum PaymentResultCode {
	AUTHORISED = 'AUTHORISED',
	CANCELLED = 'CANCELLED',
	ERROR = 'ERROR',
	REFUSED = 'REFUSED',
	PENDING = 'PENDING',
}

export type PaymentResult = {
	resultCode: PaymentResultCode;
};

export type RawPaymentResult = {
	result_code: ResultCode;
	refusal_reason_code?: string;
};
