import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

type ContentfulIdContext = {
	contentfulId?: string;
	setContentfulId: (contentfulId?: string) => void;
};

const ContentfulIdContext = createContext<ContentfulIdContext>({} as ContentfulIdContext);

type ProviderProps = {
	contentfulId?: string;
};

export const ContentfulIdProvider: FC<PropsWithChildren<ProviderProps>> = ({
	children,
	contentfulId: contentfulIdProp,
}) => {
	const [contentfulId, setContentfulId] = useState(contentfulIdProp);

	useEffect(() => {
		setContentfulId(contentfulIdProp);
	}, [contentfulIdProp]);

	const value = useMemo(() => ({ contentfulId, setContentfulId }), [contentfulId]);

	return <ContentfulIdContext.Provider value={value}>{children}</ContentfulIdContext.Provider>;
};

export const useContentfulIdContext = (): ContentfulIdContext => useContext(ContentfulIdContext);
