import { GlobalFilterStoreSchema } from '@vakantiesnl/services/src/schemas/globalFilterStoreSchema';
import { GLOBAL_FILTERS_STORAGE_NAME, GlobalFiltersState } from '@vakantiesnl/services/src/stores/globalFiltersStore';
import { DEFAULT_PARTY, unformatPaxQuery } from '@vakantiesnl/services/src/util/partyUtils';
import { GetServerSidePropsContext, NextPageContext } from 'next/types';
import nextcookies from 'next-cookies';

import { parseQueryToValidDurations } from './durations';

export function getPaxFromContext(ctx: GetServerSidePropsContext | NextPageContext): GlobalFiltersState['party'] {
	let pax;

	const paxFromQuery = ctx.query.pax;

	// First check if pax is part of query
	if (typeof paxFromQuery === 'string') {
		pax = unformatPaxQuery(paxFromQuery) ?? undefined;
	} else {
		// else check if it's stored in a cookie
		pax = getPaxFromCookie(ctx);
	}

	// if pax is not set, return the default party
	return pax ?? DEFAULT_PARTY;
}

export function getDurationsFromContext(
	ctx: GetServerSidePropsContext | NextPageContext,
): GlobalFiltersState['durations'] {
	return getDurationFromQuery(ctx) || getDurationFromCookie(ctx);
}

export function getPaxFromCookie(
	ctx?: GetServerSidePropsContext | NextPageContext,
): GlobalFiltersState['party'] | undefined {
	const globalFilterStore = nextcookies(ctx || {})[GLOBAL_FILTERS_STORAGE_NAME] as unknown;

	const result = GlobalFilterStoreSchema.safeParse(globalFilterStore);

	if (result.error) {
		return undefined;
	}

	return result.data.state.filters.party;
}

function getDurationFromQuery(ctx?: GetServerSidePropsContext | NextPageContext): GlobalFiltersState['durations'] {
	return parseQueryToValidDurations(ctx?.query.durations);
}

export function getDurationFromCookie(
	ctx?: GetServerSidePropsContext | NextPageContext,
): GlobalFiltersState['durations'] {
	const globalFilterState = nextcookies(ctx || {})[GLOBAL_FILTERS_STORAGE_NAME] as unknown;

	const result = GlobalFilterStoreSchema.safeParse(globalFilterState);

	if (result.error) {
		return null;
	}

	return result.data.state.filters.durations;
}
