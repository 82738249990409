export enum FILTER_OPTIONS {
	wifi = 'wifi',
	airco = 'airco',
	playground = 'playground',
	entertainment = 'entertainment',
	restaurant = 'restaurant',
	accessibility = 'accessibility',
	pets_allowed = 'pets_allowed',
	pool = 'pool',
	jacuzzi = 'jacuzzi',
	childrens_pool = 'childrens_pool',
	waterpark = 'waterpark',
	family_room = 'family_room',
	baby_cot = 'baby_cot',
	kids_club = 'kids_club',
	gameroom = 'gameroom',
	sport_facilities = 'sport_facilities',
}
export type Filters = Array<FILTER_OPTIONS>;

export enum DISTANCE_FILTER_OPTIONS {
	on_the_beach = 'on_the_beach',
	less_than_1km_to_the_beach = 'less_than_1km_to_the_beach',
	less_than_1km_to_the_center = 'less_than_1km_to_the_center',
}
export type DistanceFilters = Array<DISTANCE_FILTER_OPTIONS>;
