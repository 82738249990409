import { z } from 'zod';

export const ApplicationStateSchema = z.object({
	favorites: z.array(
		z
			.object({
				entityId: z.number(),
				timeStamp: z.number(),
			})
			.strip(),
	),
	userId: z.string(),
	features: z.record(
		z.string(),
		z.object({
			isEnabled: z.boolean(),
			variant: z.object({
				name: z.string(),
				enabled: z.boolean(),
				feature_enabled: z.boolean().optional(),
				payload: z
					.object({
						type: z.string(),
						value: z.string(),
					})
					.optional(),
			}),
		}),
	),
});

export const ApplicationStoreSchema = z.object({
	state: ApplicationStateSchema,
	version: z.number(),
});
