import { Search } from '@vakantiesnl/types/src';

export function isAccoOnlyOffer(
	offer?: Search.OfferItem | Search.AccommodationOnlyOffer | null,
): offer is Search.AccommodationOnlyOffer {
	if (offer && !('outbound' in offer)) {
		return true;
	}

	return false;
}
export function isPackageOffer(
	offer?: Search.OfferItem | Search.AccommodationOnlyOffer | null,
): offer is Search.OfferItem {
	if (offer && 'outbound' in offer) {
		return true;
	}

	return false;
}
