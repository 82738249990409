import { FC, PropsWithChildren, useCallback } from 'react';

import { ThemeProvider, Theme as MuiTheme } from '@mui/material/styles';
import { materialUITheme as vaknlMaterialUITheme } from '@vakantiesnl/components/src/styles/themes/vakanties/materialUITheme';
import { materialUITheme as zvrMaterialUITheme } from '@vakantiesnl/components/src/styles/themes/zoover/materialUITheme';
import { Theme } from '@vakantiesnl/types';
import mediaQuery from 'css-mediaquery';
import { getSelectorsByUserAgent } from 'react-device-detect';
import { createMakeAndWithStyles } from 'tss-react';

import { vakantiesTheme } from './vakanties/newTheme';
import { NewTheme, zooverTheme } from './zoover/newTheme';

let materialUITheme: MuiTheme;
let newTheme: NewTheme;

export const ThemeWrapper: FC<PropsWithChildren<{ theme?: Theme; userAgent?: string }>> = ({
	theme,
	userAgent,
	children,
}) => {
	materialUITheme = theme === Theme.vaknl ? vaknlMaterialUITheme : zvrMaterialUITheme;
	newTheme = theme === Theme.vaknl ? vakantiesTheme : zooverTheme;

	/** Match the mediaQuery on the serverside */
	const ssrMatchMedia = useCallback(
		(query: string) => {
			const deviceType = userAgent && getSelectorsByUserAgent(userAgent);
			return {
				matches: mediaQuery.match(query, {
					width: deviceType && deviceType.isMobile ? '0px' : materialUITheme.breakpoints.values.lg,
				}),
			};
		},
		[userAgent],
	);

	return (
		<ThemeProvider
			// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
			theme={{
				...materialUITheme,
				components: {
					...materialUITheme.components,
					MuiUseMediaQuery: {
						defaultProps: {
							ssrMatchMedia,
						},
					},
				},
			}}
		>
			{children}
		</ThemeProvider>
	);
};

export const { makeStyles: deprecatedMakeStyles } = createMakeAndWithStyles({
	useTheme: () => materialUITheme,
});

export const { makeStyles, useStyles: useNewStyles } = createMakeAndWithStyles({
	useTheme: () => newTheme,
});
