import React, { useContext, createContext, memo, FC } from 'react';

import { FooterBlock, ZvrFooterBlock } from '@vakantiesnl/types';

type Props = {
	children: React.ReactNode;
	data: FooterBlock | ZvrFooterBlock;
};

const FooterContext = createContext<FooterBlock | ZvrFooterBlock | undefined>(undefined);

const BaseFooterProvider: FC<Props> = ({ children, data }) => (
	<FooterContext.Provider value={data}>{children}</FooterContext.Provider>
);

export const useFooterContext = (): FooterBlock | ZvrFooterBlock | undefined => useContext(FooterContext);

export const FooterProvider = memo(BaseFooterProvider);
