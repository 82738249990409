import { nlNL } from '@mui/material/locale';
import { alpha, createTheme, responsiveFontSizes, DeprecatedThemeOptions, Shadows } from '@mui/material/styles';
import { Theme } from '@vakantiesnl/types';

/**
 * Theme creator for the Material UI
 * use this to overwrite some annoying global styles and minimize a need for overwriting styles.
 * https://material-ui.com/customization/theming/
 *
 */
const primaryMainColor = '#ff6f26';

const customTheme = {
	themeName: Theme.zvrnl,
	zIndex: {
		drawer: 799,
	},
	palette: {
		primary: {
			main: primaryMainColor,
			dark: '#e6560d',
			light: '#fdf1e4',
		},
		secondary: {
			light: '#ffcb03',
			main: primaryMainColor,
			dark: '#f9be32',
		},
		info: {
			main: '#57c6db',
			light: '#ebf8fa',
		},
		success: {
			main: '#83c24a',
			dark: '#368e44',
			light: '#f0faf7',
		},
		warning: {
			main: '#ffb220',
			dark: '#fc600f',
			// hover does not exist in MUI's typing, should be: '#ffbd41'
			light: '#fc600f',
		},
		error: {
			main: '#ed565d',
			light: '#fff0f0',
		},
		grey: {
			50: '#f4f4f4',
			100: '#f8f8f8',
			200: '#e8e8e8',
			300: '#d8d8d8',
			400: '#cccccc',
			500: '#949494',
			600: '#808080',
			700: '#979797',
			800: '#5c5c5c',
			900: '#4a4a4a',
		},
		sea: {
			normal: '#4a4a4a',
		},
		ratingColors: {
			darkgreen: '#006400',
			green: '#008000',
			yellow: '#FFFF00',
			red: '#FF0000',
		},
		selectboxBackground: {
			main: '#ffffff',
		},
		price: {
			main: '#4a4a4a',
		},
		emerald: {
			light: '#3d9b8b',
			main: '#3d9b8b',
			dark: '#136B5D',
		},
		flightType: {
			main: '#4a4a4a',
		},
		icons: {
			main: '#4a4a4a',
		},
		usp: {
			main: '#4a4a4a',
		},
		trustpilot: {
			main: '#00b67a',
		},
		inputIcons: {
			main: '#4a4a4a',
		},
		highlight: {
			main: primaryMainColor,
		},
		buttons: {
			main: '#ff5600',
			green: '#1dab33',
		},
		zooverOrange: {
			light: '#fdf1e4',
			main: primaryMainColor,
			pale: '#ffdbc9',
		},
		merchandise: {
			main: '#EE881C',
		},
		awards: {
			main: '#E8C542',
			secondary: '#C3C3C1',
			purple: '#b148aa',
		},
		header: {
			main: primaryMainColor,
		},
		dragAndDropFocus: {
			main: '#2196f3',
		},
		black: '#000000',
		green: { light: '#D2EED6' },
	},
	dimensions: {
		generalInfoWidth: '530px',
	},
	spacing: (factor: number): string => 0.5 * factor + 'rem',
	breakpoints: {
		values: {
			xs: 320,
			sm: 480,
			md: 768,
			lg: 1024,
			xl: 1200,
		},
	},
	gradients: {
		gold: 'linear-gradient(to right, #E8C542, #B99C43)',
		silver: 'linear-gradient(to right, #C3C3C1, #9A9D99)',
	},
	borders: {
		default: '1px solid #e8e8e8' as string,
		thick: '2px solid #4D90FE' as string,
		white: '1px solid #fff' as string,
		dark: '1px solid #949494' as string,
		main: `1px solid ${primaryMainColor}` as string,
		gray: '1px solid #aeaeae' as string,
		darkgray: '1px solid #979797' as string,
	},
	shape: {
		borderRadius: 6,
	},
	shadows: [
		'none',
		`inset 0 -0.3em 0 ${alpha('#f3ad05', 0.65)}`,
		'inset 0 -0.3em 0 #f3ad05',
		`10px 10px 20px 0 ${alpha('#aeaeae', 0.2)}`,
		`-10px -10px 20px 0 ${alpha('#fff', 0.25)}`,
		`0 2px 8px ${alpha('#000', 0.1)}`,
		'0 0 10px 0 rgb(0 0 0 / 15%)',
		...Array(21).fill('none'),
	] as Shadows,
	typography: {
		fontSize: 16, // text, rating box, link
		fontFamily: 'var(--Averta)',
		fontWeightRegular: 400,
		fontWeightMedium: 600,
		fontWeightMediumBold: 700,
		fontWeightBold: 900,
		allVariants: {
			fontFamily: 'var(--Averta)',
			color: '#4a4a4a',
		},
		body1: {
			fontSize: 16,
		},
		subtitle1: {
			fontFamily: 'var(--Averta)',
			fontSize: 14,
		},
		subtitle2: {
			fontSize: 12,
		},
		h1: {
			fontSize: 40,
		},
		h2: {
			fontSize: 24,
			marginBlockStart: 20,
			marginBlockEnd: 20,
		},
		h3: {
			fontSize: 20,
			marginBlockStart: 20,
			marginBlockEnd: 20,
		},
		h4: {
			fontSize: 16,
		},
	},
	props: {
		MuiButtonBase: {
			disableRipple: true,
		},
	},
};

export type ZooverMUITheme = typeof customTheme & DeprecatedThemeOptions;

const theme = createTheme(customTheme as DeprecatedThemeOptions & { themeName: Theme }, nlNL);
const materialUITheme = responsiveFontSizes(theme);

export { materialUITheme };
