import { z } from 'zod';

export const GlobalFilterStoreSchema = z.object({
	state: z.object({
		filters: z.object({
			party: z.array(
				// Based on the PartyComposition type, sadly zod cannot generate a schema from on a type
				z.object({
					adults: z.number().min(0).finite(),
					children: z.number().min(0).finite(),
					babies: z.number().min(0).finite(),
				}),
			),
			durations: z.union([z.string().regex(/^\d+-\d+$/), z.array(z.number().positive().finite()), z.null()]),
		}),
	}),
	version: z.number(),
});
