import { BrowserInfo } from '@adyen/adyen-web/dist/types/types';

export type AdyenPaymentMethod = {
	isValid: boolean;
	data: {
		paymentMethod: {
			type: AdyenPaymentMethodEnum;
			issuer?: string;
			encryptedCardNumber?: string;
			encryptedExpiryMonth?: string;
			encryptedExpiryYear?: string;
			encryptedSecurityCode?: string;
			holderName?: string;
		};
		browserInfo?: BrowserInfo;
	};
};

export enum AdyenPaymentMethodEnum {
	IDEAL = 'ideal',
	CREDITCARD = 'scheme',
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	BANCONTACT = 'scheme',
}
