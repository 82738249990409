import { useContext } from 'react';

import { Search } from '@vakantiesnl/types/src';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { GlobalFiltersStoreContext } from './globalFiltersContext';
import { GlobalFilterStoreState } from './globalFiltersStore';

export function useGlobalFiltersStore<T>(
	selector: (state: GlobalFilterStoreState) => T,
	equalityFn?: (left: T, right: T) => boolean,
): T {
	const store = useContext(GlobalFiltersStoreContext);

	if (!store) throw new Error('Missing GlobalFiltersContext.Provider in the tree');

	return useStoreWithEqualityFn(store, selector, equalityFn);
}

export function usePartyComposition(): Search.PartyComposition[] {
	return useGlobalFiltersStore((s) => s.filters.party);
}
