import { zoover } from '@vakantiesnl/design-tokens';
import { Theme } from '@vakantiesnl/types';

export const zooverTheme = { themeName: Theme.zvrnl, ...zoover };

// Infer theme interface based on the Zoover theme (less work than writing a shared interface).
// Both Zoover and Vakanties themes should always have the same properties available, so the theme can be used in shared components.
// If there comes a time where we need very specific tokens for either website, we can consider creating a
// specific theme with atoms unique to that website, used by components that are not shared.
export type NewTheme = typeof zooverTheme;
