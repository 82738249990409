import React, { useContext, createContext, memo } from 'react';

import { once } from 'lodash';

interface Props<T> {
	children: React.ReactNode;
	data: T;
}

const createHeaderContext = once(<T,>() => createContext<T>({} as T));

const BaseHeaderProvider = <T,>({ children, data }: Props<T>): React.ReactElement => {
	const HeaderContext = createHeaderContext<T>();

	return <HeaderContext.Provider value={data}>{children}</HeaderContext.Provider>;
};

export const useHeaderContext = <T,>(): T => useContext<T>(createHeaderContext<T>());

export const HeaderProvider = memo(BaseHeaderProvider);
