export const subtractCount = (currentCount: number, minCount: number): number => {
	return currentCount > minCount ? currentCount - 1 : 0;
};

export const addUpCount = (currentCount: number, maxCount: number): number => {
	return currentCount < maxCount ? currentCount + 1 : currentCount;
};

export const getFormattedPrice = (
	price: number,
	options?: {
		maximumFractionDigits: number;
		minimumFractionDigits: number;
	},
): string => {
	const formatter = new Intl.NumberFormat('nl-NL', {
		style: 'currency',
		currency: 'EUR',
		...options,
	});

	return formatter.format(price / 100);
};

export const getFormattedPriceWithStripe = (price: number): string => {
	if (price % 1 === 0) {
		return getFormattedPrice(price).replace(/\D00(?=\D*$)/, ',-');
	} else {
		return getFormattedPrice(price);
	}
};

export const getFormattedRoundedPrice = (price: number): string =>
	getFormattedPrice(price, {
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	})
		.replace(/\D00(?=\D*$)/, ',-')
		.replace('.', '');

export const sumObjectValues = (obj: { [key: string]: number }): number =>
	Object.values(obj).reduce((sum, value) => sum + value);
