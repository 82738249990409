import { StatusCodeError } from './customErrorTypes';

// Make sure the error we report is parsed to a supported/readable format
export function parseErrorToReport(e: unknown, message?: string): string {
	const errorAsString = errorToString(e);

	return message ? `${message}: ${errorAsString}` : errorAsString;
}

function errorToString(e: unknown): string {
	if (e instanceof StatusCodeError) {
		return `Statuscode error: ${e.statusCode}`;
	}

	if (typeof e === 'string' || e instanceof Error) {
		return `${e}`;
	}

	return JSON.stringify(e);
}
